.circle {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.left-square {
  height: 120px;
  width: 70px;
  bottom: 16px;
  left: 16px;
  top: unset;
  right: unset;
  background: var(--color-yellow-500);
  border: 4px solid var(--color-grey-500);
  padding: 4px;
  font-weight: 700;
  animation: flash ease 200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &.left-square-active {
    background: var(--color-yellow-600);
    animation: unset;
  }
}

.right-square {
  height: 120px;
  width: 70px;
  bottom: 16px;
  right: 16px;
  top: unset;
  left: unset;
  background: var(--color-yellow-500);
  border: 4px solid var(--color-grey-500);
  padding: 4px;
  font-weight: 700;
  animation: flash ease 200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &.right-square-active {
    background: var(--color-yellow-600);
    animation: unset;
  }
}

@keyframes flash {
  0% {
    background: var(--color-grey-500);
  }

  100% {
    background: var(--color-yellow-500);
  }
}

.grid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: repeat(5, minmax(0, 1fr));
}
