.grid {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  max-width: 100%;

  & > button {
    box-shadow: none;
    outline: none;
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;
    background: transparent;

    &::before {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform-origin: center center;
      border: 4px solid var(--color-primary-500);
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 4px;
      width: 100%;
      transform-origin: center center;
      background-color: #000;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    & svg {
      width: auto;
      height: auto;
    }

    &.active {
      &::before {
        content: "";
      }
    }

    &.selected {
      &::after {
        content: "";
      }
    }
  }
}

.dot-container {
  transform-origin: center center;
  margin: 0;
  padding: 0;
  aspect-ratio: 1/1;
  width: 46px;
}
