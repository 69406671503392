.circle {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.left-square {
  height: 120px;
  width: 70px;
  bottom: 16px;
  left: 16px;
  top: unset;
  right: unset;
  background: var(--color-yellow-500);
  border: 4px solid var(--color-grey-500);
  padding: 4px;
  font-weight: 700;
  animation: flash ease 200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.right-square {
  height: 120px;
  width: 70px;
  bottom: 16px;
  right: 16px;
  top: unset;
  left: unset;
  background: var(--color-yellow-500);
  border: 4px solid var(--color-grey-500);
  padding: 4px;
  font-weight: 700;
  animation: flash ease 200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes flash {
  0% {
    background: var(--color-grey-500);
  }

  100% {
    background: var(--color-yellow-500);
  }
}
