:root {
  /* Color */
  --color-primary-500: #1976d2;
  --color-red-500: hsl(5deg 100% 59%); /* #ff3f2f */
  --color-red-500-hover: hsl(5deg 100% 49%);
  --color-blue-500: hsl(220deg 100% 52%); /* #085cff */
  --color-blue-500-hover: hsl(220deg 100% 47%);
  --color-green-500: hsl(105deg 100% 44%); /* #37df00 */
  --color-green-500-hover: hsl(105deg 100% 39%);
  --color-yellow-500: hsl(47deg 100% 59%);/* #ffd12f */
  --color-yellow-500-hover: hsl(47deg 100% 49%);
  --color-yellow-600: hsl(47deg 98% 42%);/* #ffd12f */

  /* Grey values */
  --color-black-900: hsl(0deg 0% 0%); /* #000 */
  --color-black-900-hover: hsl(0deg 0% 5%);
  --color-grey-100: #f3f3f4;
  --color-white-100: hsl(0deg 0% 100%); /* #fff */
  --color-white-100-hover: hsl(0deg 0% 95%);
  --color-grey-500: #232323;

  /* Color bvariables */
  --body-background-color: var(--color-grey-100);
  --body-color: var(--color-black-900);

  /* Misc */
  --font-family:
    -apple-system,
    blinkmacsystemfont,
    "Segoe UI",
    roboto,
    oxygen,
    ubuntu,
    cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background-color: var(--body-background-color);
  overflow: hidden;

  &::backdrop {
    background-color: var(--body-background-color);
  }
}

.data {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: lightcoral;
  max-width: 300px;
  height: 50vh;
  overflow-y: auto;
}
