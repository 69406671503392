.wrapper {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

.children-wrapper {
  width: 800px;
  height: 600px;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  padding: 8px;
}

.debug {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: lightcyan;
}
